import React from "react"

import styles from "./detail-pricing-card-mobile.module.scss"

const iconGreenCheck = <div className={styles.detailPrice__icon}>
    <img src={require("../../../../images/icons/icon-green-arrow-detail-price.svg")} alt="" />
</div>

const iconBlueCheck = <div className={styles.detailPrice__icon}>
    <img src={require("../../../../images/icons/icon-blue-arrow-detail-price.svg")} alt="" />
</div>

const iconOrangeCheck = <div className={styles.detailPrice__icon}>
    <img src={require("../../../../images/icons/icon-orange-arrow-detail-price.svg")} alt="" />
</div>

const iconPurpleCheck = <div className={styles.detailPrice__icon}>
    <img src={require("../../../../images/icons/icon-purple-arrow-detail-price.svg")} alt="" />
</div>

const iconCross = <div className={styles.detailPrice__icon}>
    <img src={require("../../../../images/icons/icon-close-detail-price.svg")} alt="" />
</div>

const option = (text, icon) => {
    return <div className={styles.detailPrice__option}>
        {icon}
        <p className={styles.detailPrice__text}>{text}</p>
    </div>
}

const DetailPricingCardMobileComponent = ({ prices, onChoosePlanClick, mainPage, withOutPrice, mobileMenu }) => (
    <div className={styles.detailPrice__mobile}>
        <div className={styles.detailPrice__wrap}>
            <div className={styles.detailPrice__columns}>
                <div className={`${styles.detailPrice__column} ${styles.green} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                    <div className={styles.detailPrice__top}>
                        <div className={styles.detailPrice__title}>Мини</div>
                        <div className={styles.detailPrice__price}>{prices.mini} <span>/ мес.</span></div>
                        {mainPage ? <a href="/pricing" className={styles.detailPrice__btn}>Подробнее</a>
                          :
                          <button className={styles.detailPrice__btn} onClick={() => {onChoosePlanClick('mini')}}>Выбрать тариф</button>
                        }
                    </div>
                    <div className={styles.detailPrice__bot}>
                        {option(<>Облачное хранилище</>, iconGreenCheck)}
                        {option(<>Клиентская база <br /> (до 50 клиентов)</>, iconGreenCheck)}
                        {option(<>Неограниченное <br /> количество групп</>, iconGreenCheck)}
                    </div>
                </div>
                <div className={`${styles.detailPrice__column} ${styles.blue} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                    <div className={styles.detailPrice__top}>
                        <div className={styles.detailPrice__title}>Опти</div>
                        <div className={styles.detailPrice__price}>{prices.opti} <span>/ мес.</span></div>
                        {mainPage ? <a href="/pricing" className={styles.detailPrice__btn}>Подробнее</a>
                          :
                          <button className={styles.detailPrice__btn} onClick={() => {
                              onChoosePlanClick('opti')
                          }}>Выбрать тариф</button>
                        }
                    </div>
                    <div className={styles.detailPrice__bot}>
                        {option('СМС-рассылки', iconBlueCheck)}
                        {option('WhatsApp', iconBlueCheck)}
                        {option('Telegram', iconBlueCheck)}
                        {option('Облачное хранилище', iconBlueCheck)}
                        {option(<>Неограниченная <br /> клиентская база</>, iconBlueCheck)}
                        {option(<>Неограниченное <br /> количество групп</>, iconBlueCheck)}
                    </div>
                </div>
                <div className={`${styles.detailPrice__column} ${styles.orange} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                    <div className={styles.detailPrice__top}>
                        <div className={styles.detailPrice__popular}>
                            <img className={styles.detailPrice__popularIcon} src={require("../../../../images/3dfire.svg")} alt="" />
                            Популярно
                        </div>
                        <div className={styles.detailPrice__title}>Макси</div>
                        <div className={styles.detailPrice__price}>{prices.maxy} <span>/ мес.</span></div>
                        {mainPage ? <a href="/pricing" className={styles.detailPrice__btn}>Подробнее</a>
                          :
                          <button className={styles.detailPrice__btn} onClick={() => {
                              onChoosePlanClick('maxy')
                          }}>Выбрать тариф</button>
                        }
                    </div>
                    <div className={styles.detailPrice__bot}>
                        {option(<>Личные кабинеты <br /> клиентов</>, iconOrangeCheck)}
                        {option('IP-Телефония', iconOrangeCheck)}
                        {option('Автоуведомления', iconOrangeCheck)}
                        {option('СМС-рассылки', iconOrangeCheck)}
                        {option('WhatsApp', iconOrangeCheck)}
                        {option('Telegram', iconOrangeCheck)}
                        {option('Облачное хранилище', iconOrangeCheck)}
                        {option('Интернет-эквайринг', iconOrangeCheck)}
                        {option('Фискализация', iconOrangeCheck)}
                        {option('Форма онлайн записи', iconOrangeCheck)}
                        {option('Форма онлайн расписания', iconOrangeCheck)}
                        {option('Интеграция с Tilda', iconOrangeCheck)}
                        {option('Неограниченная клиентская база', iconOrangeCheck)}
                        {option('Неограниченное количество групп', iconOrangeCheck)}
                    </div>
                </div>
                <div className={`${styles.detailPrice__column} ${styles.purple} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                    <div className={styles.detailPrice__top}>
                        <div className={styles.detailPrice__title}>Франшизы</div>
                        <div className={styles.detailPrice__price}><span>от</span> {prices.franchise} <span>/ мес.</span></div>
                        <a className={styles.detailPrice__btn} href="/franchise">{mainPage ? 'Подробнее': 'Выбрать тариф'}</a>
                    </div>
                    <div className={styles.detailPrice__bot}>
                        {option(<>Личные кабинеты <br /> клиентов</>, iconPurpleCheck)}
                        {option('IP-Телефония', iconPurpleCheck)}
                        {option('Автоуведомления', iconPurpleCheck)}
                        {option('СМС-рассылки', iconPurpleCheck)}
                        {option('WhatsApp', iconPurpleCheck)}
                        {option('Telegram', iconPurpleCheck)}
                        {option('Облачное хранилище', iconPurpleCheck)}
                        {option('Интернет-эквайринг', iconPurpleCheck)}
                        {option('Фискализация', iconPurpleCheck)}
                        {option('Форма онлайн записи', iconPurpleCheck)}
                        {option('Форма онлайн расписания', iconPurpleCheck)}
                        {option('Интеграция с Tilda', iconPurpleCheck)}
                        {option('Неограниченная клиентская база', iconPurpleCheck)}
                        {option('Неограниченное количество групп', iconPurpleCheck)}
                    </div>
                </div>
            </div>
        </div>
        <p className={styles.detailPrice__mobilePrompt}>Сторонние сервисы оплачиваются по их тарифам. Заморозка зависит от периода подписки: для 1 и 3 месяцев - недоступно, для 6 месяцев - 1 месяц заморозки, для 1 года - 3 месяца заморозки.</p>
        <div className={`${styles.detailPrice__bot} ${mobileMenu === false ? styles.none : ''}`}>
            <div className={`${styles.detailPrice__subtitle} ${styles.detailPrice__block}`}>Основные опции, которые включены во все тарифы</div>
            {option('Интерактивное расписание', iconPurpleCheck)}
            {option('Учет посещений и абонементов', iconPurpleCheck)}
            {option('Онлайн-касса', iconPurpleCheck)}
            {option('Воронка продаж', iconPurpleCheck)}
            {option('Дубли', iconPurpleCheck)}
            {option('Учет доходов и расходов', iconPurpleCheck)}
            {option('Разграничение прав доступа сотрудников', iconPurpleCheck)}
            {option('Мобильная версия', iconPurpleCheck)}
            {option('Английский язык', iconPurpleCheck)}
            {option('Детальные отчеты', iconPurpleCheck)}
            {option('Подробная аналитика', iconPurpleCheck)}
            {option('Планировщик задач', iconPurpleCheck)}
            {option('Расчет зарплаты', iconPurpleCheck)}
            {option('Налоги и штрафы', iconPurpleCheck)}
            {option('Оценка успеваемости', iconPurpleCheck)}
            {option('Настраиваемые поля клиентов', iconPurpleCheck)}
            {option('Выбор названия разделов', iconPurpleCheck)}
            {option('Домашние задания и вебинары', iconPurpleCheck)}
            {option('Неограниченное количество кабинетов', iconPurpleCheck)}
            {option('Неограниченное количество педагогов', iconPurpleCheck)}
            {option('Неограниченное число пользователей', iconPurpleCheck)}
            {option('Импорт базы клиентов', iconPurpleCheck)}
            {option('Импорт базы лидов', iconPurpleCheck)}
            {option('Техническая чат-поддержка', iconPurpleCheck)}
            {option('База знаний', iconPurpleCheck)}
        </div>
    </div>
)

export default DetailPricingCardMobileComponent

import React from "react"

import styles from "./detail-pricing-card-desctop.module.scss"

const optionPrompt = (name, description) => (<div className={`${styles.detailPrice__option} ${styles.detailPrice__optionPrompt}`}>
    <p className={styles.detailPrice__text}>{name}</p>
    <div className={styles.detailPrice__icon}>
        <img src={require("../../../../images/icons/icon-prompt-detail-price.svg")} alt="" />
        <div className={styles.detailPrice__prompt}>{description}</div>
    </div>
</div>)

const option = (name) => (<div className={styles.detailPrice__option}>
    <p className={styles.detailPrice__text}>{name}</p>
</div>)

const disabledOption = <div className={styles.detailPrice__option}>
    <div className={styles.detailPrice__icon}>
        <img src={require("../../../../images/icons/icon-close-detail-price.svg")} alt="" />
    </div>
</div>

const greenOption = <div className={styles.detailPrice__option}>
    <div className={styles.detailPrice__icon}>
        <img src={require("../../../../images/icons/icon-green-arrow-detail-price.svg")} alt="" />
    </div>
</div>

const blueOption = <div className={styles.detailPrice__option}>
    <div className={styles.detailPrice__icon}>
        <img src={require("../../../../images/icons/icon-blue-arrow-detail-price.svg")} alt="" />
    </div>
</div>

const orangeOption = <div className={styles.detailPrice__option}>
    <div className={styles.detailPrice__icon}>
        <img src={require("../../../../images/icons/icon-orange-arrow-detail-price.svg")} alt="" />
    </div>
</div>

const purpleOption = <div className={styles.detailPrice__option}>
    <div className={styles.detailPrice__icon}>
        <img src={require("../../../../images/icons/icon-purple-arrow-detail-price.svg")} alt="" />
    </div>
</div>

const additionalOption = (name) => {
    const prompts = {
        'Личные кабинеты клиентов':
          <p>Откройте каждому клиенту Личный кабинет. Клиент сможет смотреть посещения, расписание, д/з, историю платежей и абонементов.</p>,
        'IP-Телефония *':
          <p>Подключите телефонию, чтобы получать всплывающие уведомления о входящих и исходящих звонках, автоматически
              создавать лидов и задачи для входящих звонков.
              <br/>
              <br/>
              <span>* Дополнительно оплачивается сервис по его тарифам.</span>
          </p>,
        'Автоуведомления *':
          <p>Автоматические SMS, WhatsApp и Email уведомления сотрудникам и клиентам напомнят о покупке абонемента, посещении, 
              проинформируют о переносе или отмене записи и т.д.
              <br/>
              <br/>
              <span>* Дополнительно оплачивается сервис SMS-рассылок по его тарифам.</span>
          </p>,
        'WhatsApp-рассылки *':
          <p> Оперативно рассылайте важную информацию через мессенджер: одному человеку или группе. Поздравляйте клиентов с праздниками.
              <br/>
              <br/>
              <span>* Дополнительно оплачивается сервис по его тарифам.</span>
          </p>,
        'СМС-рассылки *':
          <p> Оперативно рассылайте важную информацию: одному человеку или группе. Поздравляйте клиентов с праздниками.
              <br/>
              <br/>
              <span>* Дополнительно оплачивается сервис по его тарифам.</span>
          </p>,
        'WhatsApp-чат *':
          <p>Переписывайтесь с клиентами, лидами прямо из системы в режиме реального времени.
              <br/>
              <br/>
              <span>* Дополнительно оплачивается сервис по его тарифам.</span>
          </p>,
        'Telegram-чат *':
        <p>Переписывайтесь с клиентами, лидами прямо из системы в режиме реального времени.
          <br/>
          <br/>
          <span>* Дополнительно оплачивается сервис по его тарифам.</span>
        </p>,
        'Интернет-эквайринг':
          <p>Получайте оплаты от клиентов онлайн. Проверяйте полученные платежи в системе и в Личном кабинете плательщика.</p>,
        'Форма онлайн записи':
          <p>Ваши клиенты смогут самостоятельно записываться на услуги или занятия через форму онлайн расписания.</p>,
        'Форма онлайн расписания':
          <p>Установите интерактивное онлайн расписание на сайт и в соц.сети.</p>,
        'Интеграция с Tilda *':
          <p>Получайте заявки со своего сайта сразу в Параплан.
              <br/>
              <br/>
              <span>* Опция доступна только для пользователей с платным тарифом на Tilda.</span>
          </p>,
        'Заморозка *':
        <p>Срок заморозки зависит от периода подписки.<br/>На 1 и 3 месяцев — недоступно.<br/>На 6 месяцев — 1 месяц.<br/>На 1 год — 3 месяца.
            <br/>
            <br/>
            <span>* Минимальный срок заморозки: 1 месяц. Неиспользуемые дни заморозки сгорают.</span>
        </p>,
        'Фискализация *':
        <p>Работает с сервисами КОМТЕТ Касса и МодульКасса. Подразумевает под собой отправку чеков по платежам через интернет-эквайринг в Личном кабинете.
        </p>,
        'Количество клиентов':
          <p>Откройте каждому ученику Личный кабинет. Ученик сможет смотреть посещения, расписание, д/з, историю
            платежей и абонементов.</p>,
        'Количество групп':
          <p>Откройте каждому ученику Личный кабинет. Ученик сможет смотреть посещения, расписание, д/з, историю
              платежей и абонементов.</p>,
        'Онлайн-касса *':
        <p>Формируйте чеки после каждой финансовой операции и моментально отправляйте их покупателям и в налоговую.
            <br/>
            <br/>
            <span>* Дополнительно оплачивается сервис по его тарифам.</span>
        </p>,
        'Облачное хранилище *':
          <p>Интеграция с Яндекс.Диск и Google Drive позволяет прикреплять 
            к ученикам файлы в самой системе и в Личном кабинете ученика.</p>,
    }
    return optionPrompt(name, prompts[name])
}

const mini = (available) => available === 'y' ? greenOption : disabledOption
const opti = (available) => available === 'y' ? blueOption : disabledOption
const maxy = (available) => available === 'y' ? orangeOption : disabledOption
const francise = (available) => available === 'y' ? purpleOption : disabledOption
const text = (text) => <div className={styles.detailPrice__option}>
  <p className={styles.detailPrice__text}>{text}</p>
</div>

const additionalOptions = [
  [additionalOption('Личные кабинеты клиентов'),    mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('IP-Телефония *'),              mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Автоуведомления *'),           mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('WhatsApp-рассылки *'),         mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('СМС-рассылки *'),              mini('n'),                opti('y'),                  maxy('y'),            francise('y')         ],
  [additionalOption('WhatsApp-чат *'),              mini('n'),                opti('y'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Telegram-чат *'),              mini('n'),                opti('y'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Интернет-эквайринг'),          mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Фискализация *'),              mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Форма онлайн записи'),         mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Форма онлайн расписания'),     mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Интеграция с Tilda *'),        mini('n'),                opti('n'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Облачное хранилище *'),        mini('y'),                opti('y'),                  maxy('y'),            francise('y')         ],
  [additionalOption('Заморозка *'),                 mini('y'),                opti('y'),                  maxy('y'),            francise('y')         ],                   
  [option('Количество клиентов'),                   text('до 50 клиентов'),   text('неограничено'),       text('неограничено'), text('неограничено')  ],
  [option('Количество групп'),                      text('неограничено'),     text('неограничено'),       text('неограничено'), text('неограничено')  ],
]

const options = [
    [option('Интерактивное расписание'),                mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Учет посещений и абонементов'),            mini('y'), opti('y'), maxy('y'), francise('y')],
    [additionalOption('Онлайн-касса *'),                mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Дубли'),                                   mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Воронка продаж'),                          mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Форма сбора заявок'),                      mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Учет доходов и расходов'),                 mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Разграничение прав доступа сотрудников'),  mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Мобильная версия'),                        mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Английский язык'),                         mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Детальные отчеты'),                        mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Подробная аналитика'),                     mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Планировщик задач'),                       mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Расчет зарплаты'),                         mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Налоги и штрафы'),                         mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Оценка успеваемости'),                     mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Выбор названия разделов'),                 mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Настраиваемые поля клиентов'),             mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Домашние задания и вебинары'),             mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Неограниченное количество кабинетов'),     mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Неограниченное количество сотрудников'),   mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Неограниченное число пользователей'),      mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Импорт базы клиентов'),                    mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Импорт базы лидов'),                       mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('Техническая чат-поддержка'),               mini('y'), opti('y'), maxy('y'), francise('y')],
    [option('База знаний'),                             mini('y'), opti('y'), maxy('y'), francise('y')],
]

const DetailPricingCardDesctopComponent = ({prices, onChoosePlanClick, mainPage, withOutPrice, integration}) => (
    <div className={`${styles.detailPrice__wrap} ${integration == true ? styles.integration : ""}`}>
        <div className={styles.detailPrice__desctop}>
            <div className={`${styles.detailPrice__column} ${styles.firstColumn} ${styles.alignLeft} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                <div className={styles.detailPrice__top}>
                    <div className={styles.detailPrice__title}>Стоимость подписки <br /> на 1 месяц <br /> и функциональность</div>
                </div>
                <div className={styles.detailPrice__center}>
                    <div className={`${styles.detailPrice__subtitle} ${styles.detailPrice__block}`}>Дополнительные опции</div>
                    {additionalOptions.map(option => option[0])}
                </div>
                {!mainPage && <div className={styles.detailPrice__bot}>
                    <div className={`${styles.detailPrice__subtitle} ${styles.detailPrice__block}`}>Основные опции</div>
                    {options.map(option => option[0])}
                </div>}
            </div>
            <div className={`${styles.detailPrice__column} ${styles.green} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                <div className={styles.detailPrice__top}>
                    <div className={styles.detailPrice__title}>Мини</div>
                    <div className={styles.detailPrice__price}>{prices.mini} <span>/ мес.</span></div>
                    {mainPage ? <a className={styles.detailPrice__btn} href="/pricing">Подробнее</a>:<button className={styles.detailPrice__btn} onClick={() => {onChoosePlanClick('mini')}}>
                        Выбрать тариф
                    </button>}
                </div>
                <div className={styles.detailPrice__center}>
                    <div className={styles.detailPrice__block}></div>
                    {additionalOptions.map(option => option[1])}
                </div>
                {!mainPage && <div className={styles.detailPrice__bot}>
                    <div className={styles.detailPrice__block}></div>
                    {options.map(option => option[1])}
                </div>}
            </div>
            <div className={`${styles.detailPrice__column} ${styles.blue} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                <div className={styles.detailPrice__top}>
                    <div className={styles.detailPrice__title}>Опти</div>
                    <div className={styles.detailPrice__price}>{prices.opti} <span>/ мес.</span></div>
                    {mainPage ? <a className={styles.detailPrice__btn} href="/pricing">Подробнее</a>:
                      <button className={styles.detailPrice__btn} onClick={() => {onChoosePlanClick('opti')}}>
                          Выбрать тариф
                      </button>
                    }
                </div>
                <div className={styles.detailPrice__center}>
                    <div className={styles.detailPrice__block}></div>
                    {additionalOptions.map(option => option[2])}
                </div>
                {!mainPage && <div className={styles.detailPrice__bot}>
                    <div className={styles.detailPrice__block}></div>
                    {options.map(option => option[2])}
                </div>}
            </div>
            <div className={`${styles.detailPrice__column} ${styles.orange} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                <div className={styles.detailPrice__top}>
                    <div className={styles.detailPrice__popular}>
                      <img className={styles.detailPrice__popularIcon} src={require("../../../../images/3dfire.svg")} alt="" />
                      Популярно
                    </div>
                    <div className={styles.detailPrice__title}>Макси</div>
                    <div className={styles.detailPrice__price}>{prices.maxy} <span>/ мес.</span></div>
                    {mainPage ? <a className={styles.detailPrice__btn} href="/pricing">
                        Подробнее
                    </a>:
                      <button className={styles.detailPrice__btn}  onClick={() => {onChoosePlanClick('maxy')}}>
                          Выбрать тариф
                      </button>
                    }
                </div>
                <div className={styles.detailPrice__center}>
                    <div className={styles.detailPrice__block}></div>
                    {additionalOptions.map(option => option[3])}

                </div>
                {!mainPage && <div className={styles.detailPrice__bot}>
                    <div className={styles.detailPrice__block}></div>
                    {options.map(option => option[3])}
                </div>}
            </div>
            <div className={`${styles.detailPrice__column} ${styles.purple} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
                <div className={styles.detailPrice__top}>
                    <div className={styles.detailPrice__title}>Франшизы</div>
                    <div className={styles.detailPrice__price}><span>от</span> {prices.franchise} <span>/ мес.</span></div>
                    <a className={styles.detailPrice__btn} href="/franchise">{mainPage? 'Подробнее': 'Выбрать тариф'}</a>
                </div>
                <div className={styles.detailPrice__center}>
                    <div className={styles.detailPrice__block}></div>
                    {additionalOptions.map(option => option[4])}
                </div>
                {!mainPage && <div className={styles.detailPrice__bot}>
                    <div className={styles.detailPrice__block}></div>
                    {options.map(option => option[4])}
                </div>}
            </div>
        </div>
    </div>
)

export default DetailPricingCardDesctopComponent
